import styled, { css } from 'styled-components'
import {
  Tab as UnstyledTab,
  TabList as UnstyledTabList,
  Tabs as UnstyledTabs,
  TabPanel as UnstyledTabPanel
} from 'react-tabs'

import {
  textBold,
  lightGray,
  largeMargin,
  gray60Percent,
  lighterGray,
  bigText
} from '../../Styles/settings/Constants'

const Tabs = styled(UnstyledTabs)`
  border: none;
  background: transparent;
  margin-top: ${props => props.marginTop || '6rem'};
`
const TabList = styled(UnstyledTabList)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 ${props => props['data-margin-bottom'] ? largeMargin : 0} 0;
`

const Tab = styled(UnstyledTab).attrs({
  selectedClassName: 'selected',
  disabledClassName: 'disabled'
})`
  padding: 0.5rem 2rem;
  text-align: center;
  list-style: none;
  cursor: pointer;
  color: ${gray60Percent};
  font-size: ${bigText};
  font-weight: ${textBold};

  ${({ uppercase }) => uppercase && css`
    text-transform: uppercase;
  `}

  ${({ variant }) => variant === 'filled' && css`
    &.selected {
      background: ${lighterGray};
      color: ${props => props.theme.color};
      border-bottom: 0px !important;
    }
  `}

  ${({ newExtract }) => newExtract && css`
    border-radius: 1rem 1rem 0 0;
    padding: 0.5rem 5rem;
    height: 100%;
    margin-bottom:-3px;
    border: 1px solid rgba(244, 244, 244, 1);

    &.selected {
      background: #F4F4F4;
      font-weight: ${textBold};
      color: ${props => props.theme.color};
      border-bottom: 0px !important;
    }
  `}

  &:first-child {
    border-left: none;
  }
  &.selected {
    font-weight: ${textBold};
    color: ${props => props.theme.color};
    border-bottom: 3px solid ${props => props.theme.color};
  }
  &.disabled {
    color: ${lightGray};
    cursor: not-allowed;
  }
`
const TabPanel = styled(UnstyledTabPanel).attrs({ selectedClassName: 'selected' })`
  display: none;
  &.selected {
    display: block;
  }
`
Tab.tabsRole = 'Tab'
Tabs.tabsRole = 'Tabs'
TabPanel.tabsRole = 'TabPanel'
TabList.tabsRole = 'TabList'

export { Tab, TabList, Tabs, TabPanel }
