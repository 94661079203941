import React from 'react'
import Grid from '../Grid/Grid'

const CreateSaleStepOneTemplate = (locals) => (
  <Grid noPadding>
    {('amount' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.amount}
      </Grid.Row>
    )}
    {('dueDate' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.dueDate}
      </Grid.Row>
    )}
    {('description' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.description}
      </Grid.Row>
    )}
  </Grid>
)

export default CreateSaleStepOneTemplate
