import React from 'react'
import Proptypes from 'prop-types'
import { Line, TitleContainer, TitleItem } from './Title.styled'

const Title = ({ children, small, big, bar, line, noPadding, noTransform, ...props }) => {
  return (
    <TitleContainer line={line}>
      <TitleItem noPadding={noPadding} noTransform={noTransform} small={small} big={big} bar={bar} {...props}>
        {children}
      </TitleItem>
      {line && (
        <Line />
      )}
    </TitleContainer>
  )
}

Title.propTypes = {
  children: Proptypes.oneOfType([Proptypes.string, Proptypes.node]),
  small: Proptypes.bool,
  big: Proptypes.bool,
  bar: Proptypes.bool,
  line: Proptypes.bool,
  noPadding: Proptypes.bool,
  noTransform: Proptypes.bool
}

Title.defaultProps = {
  children: ''
}

export default Title
