import React from 'react'
import Grid from '../Grid/Grid'

const AddPosTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row auto>
      {locals.inputs.token}
    </Grid.Row>
  </Grid>
)

export default AddPosTemplate
