import React from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'

export const Tooltip = ({
  id,
  children
}) => {
  return (
    <ReactTooltip className='custom-tooltip-radius' id={id} tooltipRadius='5'>
      {children}
    </ReactTooltip>
  )
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any
}
