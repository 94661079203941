import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { biggerText, bigText, darkGray, lightGray, middleMargin, normalText, textBold } from 'src/Styles/settings/Constants'

const SubTitle = ({ children, small, big, line, themeColor, onClick, ...props }) => {
  return (
    <SubTitleContainer line={line} onClick={onClick} {...props}>
      <SubTitleItem small={small} big={big} themeColor={themeColor}>
        { children }
      </SubTitleItem>
      {line && <Line />}
    </SubTitleContainer>
  )
}

SubTitle.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  big: PropTypes.bool,
  line: PropTypes.bool,
  themeColor: PropTypes.bool,
  onClick: PropTypes.func
}

const SubTitleContainer = styled.div`
  display: initial;

  ${({ line }) => line && css`
    display: flex;
  `}

  ${({ onClick }) => !!onClick && css`
    cursor: pointer;
  `}
`

const SubTitleItem = styled.div`
  display: initial;
  font-size: ${bigText};
  color: ${props => props.themeColor ? props.theme.color : darkGray};
  line-height: normal;
  white-space: nowrap;
  padding-right: ${middleMargin};

  > strong {
    font-weight: ${textBold};
  }

  ${({ small }) => small && css`
    font-size: ${normalText};
  `}

  ${({ big }) => big && css`
    font-size: ${biggerText};
  `}
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: auto;
  background-color: ${lightGray};
`

export default SubTitle
