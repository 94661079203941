import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { white, textBold, normalText, spaceMargin } from '../../Styles/settings/Constants'

const TwiceTitleWithPipe = ({ titleOne, titleTwo }) => (
  <Fragment>
    <GrayLabel>{titleOne}</GrayLabel>
    <GrayLabel margin>|</GrayLabel>
    <ColorLabel>{titleTwo}</ColorLabel>
  </Fragment>
)

TwiceTitleWithPipe.propTypes = {
  titleOne: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  titleTwo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}

const GrayLabel = styled.label`
  color: ${white};
  font-weight: ${textBold};
  font-size: ${normalText};

  ${({ margin }) => margin && css`
    margin-left: ${spaceMargin};
  `}
`

const ColorLabel = styled.label`
  color: ${white};
  font-size: ${normalText};
  margin-left: ${spaceMargin};

  > strong {
    font-weight: ${textBold};
  }
`

export default TwiceTitleWithPipe
