import React from 'react'
import Grid from '../Grid/Grid'

const AddWebhookTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row auto>
      <Grid.Row>
        <Grid.Col cols={3}>
          {locals.inputs.method}
        </Grid.Col>
        <Grid.Col cols={4}>
          {locals.inputs.url}
        </Grid.Col>
        <Grid.Col cols={4}>
          {locals.inputs.description}
        </Grid.Col>
        <Grid.Col cols={5}>
          {locals.inputs.event}
        </Grid.Col>
      </Grid.Row>
    </Grid.Row>
  </Grid>
)

export default AddWebhookTemplate
