import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const InnerHeader = ({ children, marginTop, marginBottom, configMargin }) => (
  <Container marginTop={marginTop} marginBottom={marginBottom} configMargin={configMargin}>
    { children }
  </Container>
)

InnerHeader.propTypes = {
  children: PropTypes.element,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  configMargin: PropTypes.bool
}

export default InnerHeader

const Container = styled.div`
  width: 100%;
  
  ${({ marginTop }) => marginTop && css`
    margin-top: ${marginTop};
  `}
  
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: ${marginBottom};
  `}
  
  ${({ configMargin }) => configMargin && css`
    margin: 2rem 0 2rem 0;
  `}
`
