import React from 'react'
import Grid from '../Grid/Grid'

const CreateTransferTemplate = (locals, step) => (
  <Grid noPadding>
    {
      step === 1 && <>
        <Grid.Row auto>
          {locals.inputs.amount}
        </Grid.Row>
        <Grid.Row auto>
          <Grid.Col cols={3}>
            {locals.inputs.transferType}
          </Grid.Col>
        </Grid.Row>
      </>
    }
    {
      step === 2 && <>
        <Grid.Row auto>
          {locals.inputs.document}
        </Grid.Row>
      </>
    }
    {
      step === 3 && <>
        <Grid.Row auto>
          {locals.inputs.description}
        </Grid.Row>
      </>
    }
    <>
      <Grid.Row auto>
        {locals.inputs.p2pForm}
      </Grid.Row>
    </>

  </Grid>
)

export default CreateTransferTemplate
