import React from 'react'
import Grid from '../Grid/Grid'

const AddSellerTemplate = (locals) => (
  <Grid noPadding>
    {('firstName' in locals.inputs && 'lastName' in locals.inputs) && (
      <Grid.Row>
        <Grid.Col cols={8}>
          {locals.inputs.firstName}
        </Grid.Col>
        <Grid.Col cols={8}>
          {locals.inputs.lastName}
        </Grid.Col>

      </Grid.Row>
    )}
    {('businessName' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.businessName}
      </Grid.Row>
    )}
    {('statement' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.statement}
      </Grid.Row>
    )}
    <Grid.Row>
      <Grid.Col cols={8}>
        {locals.inputs.document}
      </Grid.Col>
      {('birthDate' in locals.inputs) && (
        <Grid.Col cols={8}>
          {locals.inputs.birthDate}
        </Grid.Col>
      )}
      {('businessOpeningDate' in locals.inputs) && (
        <Grid.Col cols={8}>
          {locals.inputs.businessOpeningDate}
        </Grid.Col>
      )}
    </Grid.Row>
    {('email' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.email}
      </Grid.Row>
    )}

    {('businessEmail' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.businessEmail}
      </Grid.Row>
    )}
    {('phoneNumber' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.phoneNumber}
      </Grid.Row>
    )}

    {('revenue' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.revenue}
      </Grid.Row>
    )}

    {('merchant' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.merchant}
      </Grid.Row>
    )}

    {('invite' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.invite}
      </Grid.Row>
    )}
  </Grid>
)

export default AddSellerTemplate
