import React from 'react'
import styled from 'styled-components'
import Grid from '../Grid/Grid'

const SupportInfoTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row>
      <FormComponent cols={16}>
        <Grid.Row auto>
          {locals.inputs.email}
        </Grid.Row>
        <Grid.Row auto>
          {locals.inputs.phoneNumber}
        </Grid.Row>
      </FormComponent>
    </Grid.Row>
  </Grid>
)

const FormComponent = styled(Grid.Col)`
  margin-top: 1rem;
`

export default SupportInfoTemplate
