import React from 'react'
import Grid from '../Grid/Grid'

const SellerOwnerDataTemplate = (locals) => (
  <Grid>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.firstName}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.document}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.email}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.lastName}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.birthdate}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.phone}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default SellerOwnerDataTemplate
