import React from 'react'
import Grid from '../Grid/Grid'

const AddAddressTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row>
      <Grid.Col cols={8}>
        {locals.inputs.postalCode}
      </Grid.Col>
      <Grid.Col cols={8}>
        {locals.inputs.state}
      </Grid.Col>
    </Grid.Row>

    <Grid.Row>
      <Grid.Col cols={8}>
        {locals.inputs.neighborhood}
      </Grid.Col>
      <Grid.Col cols={8}>
        {locals.inputs.city}
      </Grid.Col>
    </Grid.Row>

    <Grid.Row auto>
      {locals.inputs.line1}
    </Grid.Row>

    <Grid.Row>
      <Grid.Col cols={8}>
        {locals.inputs.line2}
      </Grid.Col>
      <Grid.Col cols={8}>
        {locals.inputs.line3}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default AddAddressTemplate
