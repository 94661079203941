import React from 'react'
import styled from 'styled-components'
import Grid from '../Grid/Grid'
import SubTitle from '../Title/SubTitle'

const AccountTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row>
      <Grid.Col cols={7}>
        <Subtitles auto>
          <Grid.Col>
            <SubTitle line>Dados <strong>da Empresa</strong></SubTitle>
          </Grid.Col>
        </Subtitles>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.statement}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.businessName}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.document}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.businessOpeningDate}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.email}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.phoneNumber}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.site}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.description}
          </FormComponent>
        </Grid.Row>
      </Grid.Col>
      <Grid.Col cols={2} />
      <Grid.Col cols={7}>
        <Grid.Row>
          <Grid.Col cols={14}>
            <Subtitles auto>
              <SubTitle line>Endereço <strong>da Empresa</strong></SubTitle>
            </Subtitles>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.postalCode}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.line1}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.line2}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.line3}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.neighborhood}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.city}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.state}
          </FormComponent>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={7}>
        <Subtitles auto>
          <SubTitle line>Dados <strong>do Proprietário</strong></SubTitle>
        </Subtitles>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.nameOwner}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.taxPayerId}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.phoneOwner}
          </FormComponent>
          <FormComponent cols={8}>
            {locals.inputs.emailOwner}
          </FormComponent>
        </Grid.Row>
        <Grid.Row>
          <FormComponent cols={8}>
            {locals.inputs.birthDate}
          </FormComponent>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

const Subtitles = styled(Grid.Row)`
  margin-top: 3rem;
`

const FormComponent = styled(Grid.Col)`
  margin-top: 1rem;
`

export default AccountTemplate
