import React from 'react'
import Grid from '../Grid/Grid'

const SellerInfoIndividualTemplate = (locals) => (
  <Grid>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.taxpayer_id}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.statement_descriptor}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.created_at}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.first_name}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.mcc}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.description}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.last_name}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.email}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.website}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.birthdate}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.phone_number}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.revenue}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default SellerInfoIndividualTemplate
