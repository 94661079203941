import React from 'react'
import * as PropTypes from 'prop-types'

import Grid from '../Grid/Grid'
import styled from 'styled-components'

const Container = ({ children, headerMargin, backofficeDisclaimerMargin }) => {
  const getElementsPosition = () => {
    let columns = React.Children.toArray(children)

    let elements = {}

    if (columns.length === 1) {
      elements.singleColumn = columns[0]
    } else if (columns.length === 2) {
      elements.leftColumn = columns[0]
      elements.rightColumn = columns[1]
    } else if (columns.length === 3) {
      elements.leftColumn = columns[0]
      elements.middleColumn = columns[1]
      elements.rightColumn = columns[2]
    }

    return elements
  }

  const elements = getElementsPosition()

  return (
    <Wrapper headerMargin={headerMargin} backofficeDisclaimerMargin={backofficeDisclaimerMargin}>
      {elements.innerHeader && (
        <Grid.Row auto>
          {elements.innerHeader}
        </Grid.Row>
      )}
      <Grid.Row>
        {elements.singleColumn &&
          <Grid.Col cols={16} mobileCols={8}>
            { elements.singleColumn }
          </Grid.Col>
        }

        {elements.leftColumn &&
          <Grid.Col cols={3} mobileCols={8}>
            { elements.leftColumn }
          </Grid.Col>
        }

        {elements.middleColumn &&
          <Grid.Col cols={10} mobileCols={8}>
            { elements.middleColumn }
          </Grid.Col>
        }

        {elements.rightColumn && elements.middleColumn &&
          <Grid.Col cols={3} mobileCols={8}>
            { elements.rightColumn }
          </Grid.Col>
        }

        {elements.rightColumn && !elements.middleColumn &&
          <Grid.Col cols={13} mobileCols={8}>
            { elements.rightColumn }
          </Grid.Col>
        }
      </Grid.Row>
    </Wrapper>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  headerMargin: PropTypes.bool,
  backofficeDisclaimerMargin: PropTypes.bool
}

export default Container

const Wrapper = styled.div`
  margin-bottom: 0;
`
