import React from 'react'
import Grid from '../Grid/Grid'

const LoginTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row bigMarginBottom>
      <Grid.Col cols={16}>
        {locals.inputs.email}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={16}>
        {locals.inputs.password}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default LoginTemplate
