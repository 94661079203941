import React from 'react'
import Grid from '../Grid/Grid'

const CreateSaleStepTwoTemplate = (locals) => (
  <Grid noPadding>
    {('document' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.document}
      </Grid.Row>
    )}
    {('name' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.name}
      </Grid.Row>
    )}
    {('email' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.email}
      </Grid.Row>
    )}
    {('postalCode' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.postalCode}
      </Grid.Row>
    )}
    <Grid.Row>
      {('street' in locals.inputs) && (
        <Grid.Col cols={12}>
          {locals.inputs.street}
        </Grid.Col>
      )}
      {('number' in locals.inputs) && (
        <Grid.Col cols={4}>
          {locals.inputs.number}
        </Grid.Col>
      )}
    </Grid.Row>
    {('complement' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.complement}
      </Grid.Row>
    )}
    {('neighborhood' in locals.inputs) && (
      <Grid.Row auto>
        {locals.inputs.neighborhood}
      </Grid.Row>
    )}
    <Grid.Row>
      {('city' in locals.inputs) && (
        <Grid.Col cols={12}>
          {locals.inputs.city}
        </Grid.Col>
      )}
      {('state' in locals.inputs) && (
        <Grid.Col cols={4}>
          {locals.inputs.state}
        </Grid.Col>
      )}
    </Grid.Row>
  </Grid>
)

export default CreateSaleStepTwoTemplate
