import styled, { css } from 'styled-components'

import {
  bigText,
  normalText,
  biggerText,
  textBold,
  lightGray,
  darkGray
} from 'src/Styles/settings/Constants'

export const TitleContainer = styled.div`
  display: initial;

  ${({ line }) => line && css`
    display: flex;
  `}
`

export const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: auto;
  background-color: ${lightGray};
`

export const TitleItem = styled.div`
  display: initial;
  padding: 3px 10px;
  font-size: ${bigText};
  white-space: nowrap;
  color: ${darkGray};
  line-height: 22px;
  text-transform: ${({ noTransform }) => noTransform ? 'none' : 'uppercase'};

  > strong {
    color: ${({ theme: { color } }) => color};
    font-weight: ${textBold};
  }

  ${({ bar }) => bar && css`
    border-left: 3px solid ${props => props.theme.color};
    padding: 3px 10px;
  `}

  ${({ small }) => small && css`
    font-size: ${normalText};
    line-height: 20px;
  `}

  ${({ big }) => big && css`
    font-size: ${biggerText};
    line-height: 24px;
  `}

  ${({ noPadding }) => noPadding && css`
    padding: 0;
    padding-right: 10px;
  `}
`
