import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Grid from '../Grid/Grid'

import { darkerGray, smallerText, normalText, textBold, smallMargin } from '../../Styles/settings/Constants'

const TitleInfoPage = ({ children, onClick, link, offset, ...props }) => (
  <Row link={link}>
    <Grid.Col offset={offset} cols={7}>
      <Text {...props} link={link} onClick={onClick ? () => onClick() : undefined}>
        {children}
      </Text>
    </Grid.Col>
  </Row>
)

TitleInfoPage.defaultProps = {
  offset: 1
}

TitleInfoPage.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.bool,
  offset: PropTypes.number
}

const Row = styled(Grid.Row)`
  margin: 0;

  ${({ link }) => link && css`
    margin-bottom: ${smallMargin};
  `}
`

const Text = styled.h6`
  color: ${darkerGray};
  font-size: ${smallerText};
  font-weight: ${textBold};
  text-transform: ${({ link }) => link ? 'none' : 'uppercase'};
  margin: 0;

  ${({ link }) => link && css`
    font-size: ${normalText};
  `}

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}
`

export default TitleInfoPage
