import React from 'react'
import styled from 'styled-components'

import Grid from '../Grid/Grid'
import Card from '../Card/Card'
import SubTitle from '../Title/SubTitle'

const AddBankingTemplate = (locals, handleBankingForm) => (
  <CardWrapper noTheme buttonClose handleClose={handleBankingForm}>
    <Grid.Row>
      <Grid.Col cols={4}>
        <SubTitle small>
          Cadastrar <strong>Conta</strong>
        </SubTitle>
      </Grid.Col>
    </Grid.Row>
    <Container noPadding>
      <Grid.Row auto>
        <Grid.Col cols={16}>
          {locals.inputs.type}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row auto>
        <Grid.Col cols={16}>
          {locals.inputs.bankCode}
        </Grid.Col>
      </Grid.Row>

      <AccountContainer>
        <Grid.Row auto>
          <Grid.Col cols={8}>
            {locals.inputs.routingNumber}
          </Grid.Col>
          <Grid.Col cols={8}>
            {locals.inputs.routingCheckDigit}
          </Grid.Col>
        </Grid.Row>
      </AccountContainer>

      <Grid.Row auto>
        <Grid.Col cols={16}>
          {locals.inputs.accountNumber}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row auto>
        <Grid.Col cols={16}>
          {locals.inputs.holderName}
        </Grid.Col>
      </Grid.Row>

      <Grid.Row auto>
        <Grid.Col cols={16}>
          {locals.inputs.received}
        </Grid.Col>
      </Grid.Row>
    </Container>
  </CardWrapper>
)

const CardWrapper = styled(Card)`
  margin-top: 2rem;
  margin-bottom: 4rem;
`

const Container = styled(Grid)`
  ${Grid.Row} {
    margin-bottom: 2.5rem;
  }
`

const AccountContainer = styled.div`
  margin-bottom: -1.5rem;
`

export default AddBankingTemplate
