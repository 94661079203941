import React from 'react'
import styled from 'styled-components'

import Grid from '../Grid/Grid'
import { darkGray } from '../../Styles/settings/Constants'

const AddBankAccountTemplate = locals => (
  <Grid noPadding>
    <Grid.Row>
      <Grid.Col cols={2}>
        <Grid.Row auto>
          {locals.inputs.type}
        </Grid.Row>
      </Grid.Col>
      <Grid.Col cols={3}>
        <Grid.Row auto>
          {locals.inputs.bankCode}
        </Grid.Row>
      </Grid.Col>
      <Grid.Col cols={2}>
        <Grid.Row auto>
          {locals.inputs.routingNumber}
        </Grid.Row>
      </Grid.Col>
      <Grid.Col cols={1}>
        <Grid.Row auto>
          <Separator>-</Separator>
          {locals.inputs.routingCheckDigit}
        </Grid.Row>
      </Grid.Col>
      <Grid.Col cols={2}>
        <Grid.Row auto>
          {locals.inputs.accountNumber}
        </Grid.Row>
      </Grid.Col>
      <Grid.Col cols={3}>
        <Grid.Row auto>
          {locals.inputs.holderName}
        </Grid.Row>
      </Grid.Col>
      <Grid.Col cols={3}>
        <Grid.Row auto>
          {locals.inputs.document}
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row auto>
      {locals.inputs.setDefaultCredit}
    </Grid.Row>
  </Grid>
)

export default AddBankAccountTemplate

const Separator = styled.div`
  color: ${darkGray};
  font-size: 1.8rem;
  display: flex;
  align-items: center;
`
