import React from 'react'
import Grid from '../Grid/Grid'

const ChangePassTemplate = locals => (
  <Grid noPadding>
    <Grid.Row>
      <Grid.Col cols={16}>
        {locals.inputs.currentPass}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={16}>
        {locals.inputs.newPass}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={16}>
        {locals.inputs.confirmNewPass}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default ChangePassTemplate
