import React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import {
  footerHeight,
  lightestGray
} from 'src/Styles/settings/Constants'

const Footer = ({ children }) => {
  return (
    <FooterWrapper>
      {children}
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  background-color: ${lightestGray};
  margin-top: 3.5rem;
  margin-bottom: 2rem;
  height: ${footerHeight};
  width: 100%;
`
Footer.propTypes = {
  children: PropTypes.object
}

export default Footer
