import React from 'react'
import * as PropTypes from 'prop-types'

import Grid from '../Grid/Grid'

const MainTemplate = ({ children, noPadding }) => {
  return (
    <Grid noPadding={noPadding}>
      { children }
    </Grid>
  )
}

MainTemplate.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  noPadding: PropTypes.bool
}

export default MainTemplate
