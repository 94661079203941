import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import TopHeader from '../../Components/TopHeader/TopHeader'

const Menu = ({ history }) => (
  <Fragment>
    <TopHeader history={history} />
  </Fragment>
)

Menu.propTypes = {
  history: PropTypes.object
}

export default Menu
