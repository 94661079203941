import React from 'react'
import Grid from '../Grid/Grid'

const SellerInfoBusinessTemplate = (locals) => (
  <Grid>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.ein}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.created_at}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.business_phone}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.business_name}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.mcc}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.business_description}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.statement_descriptor}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.business_email}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.business_website}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={6}>
        {locals.inputs.revenue}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default SellerInfoBusinessTemplate
