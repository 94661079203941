import React from 'react'
import styled from 'styled-components'
import Grid from '../Grid/Grid'
import SubTitle from '../Title/SubTitle'
import Button from 'src/UIComponents/Button/ButtonDefault'

const PersonalDataTemplate = (locals, toggleViewMode, viewMode) => (
  <Grid noPadding>
    <Grid.Row>
      <Grid.Col cols={12}>
        <SubTitle small line>Dados <strong>Pessoais</strong></SubTitle>
      </Grid.Col>
      {
        viewMode === 'view' && (
          <Grid.Col cols={4}>
            <ButtonStyled
              ghost
              outline
              onClick={toggleViewMode}
              icon='/assets/icons/actions/edit.svg'
              data-test='my-profile-edit-button'
            >
              Editar
            </ButtonStyled>
          </Grid.Col>
        )
      }
    </Grid.Row>
    <Grid.Row auto>
      {locals.inputs.firstName}
    </Grid.Row>
    <Grid.Row auto>
      {locals.inputs.lastName}
    </Grid.Row>
    <Grid.Row auto>
      {locals.inputs.username}
    </Grid.Row>
  </Grid>
)

const ButtonStyled = styled(Button)`
  display: flex;
  width: 88px;
  margin-top: -7px;
`

export default PersonalDataTemplate
