import React from 'react'
import Grid from '../Grid/Grid'

const SellerAdditionalInfoTemplate = (locals) => (
  <Grid>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.sellerId}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.referenceId}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default SellerAdditionalInfoTemplate
