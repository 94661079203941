import React from 'react'
import Grid from '../Grid/Grid'

const SellerAddressTemplate = (locals) => (
  <Grid>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.postalCode}
      </Grid.Col>
      <Grid.Col cols={5}>
        {locals.inputs.line3}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.state}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.line1}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.neighborhood}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={5}>
        {locals.inputs.line2}
      </Grid.Col>
      <Grid.Col cols={6}>
        {locals.inputs.city}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default SellerAddressTemplate
