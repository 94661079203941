import React from 'react'
import Grid from '../Grid/Grid'

const InviteUserTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row auto>
      <Grid.Row>
        <Grid.Col cols={4}>
          {locals.inputs.firstName}
        </Grid.Col>
        <Grid.Col cols={4}>
          {locals.inputs.lastName}
        </Grid.Col>
        <Grid.Col cols={4}>
          {locals.inputs.email}
        </Grid.Col>
        <Grid.Col cols={4}>
          {locals.inputs.profile}
        </Grid.Col>
      </Grid.Row>
    </Grid.Row>
  </Grid>
)

export default InviteUserTemplate
