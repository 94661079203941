import React from 'react'
import Grid from '../Grid/Grid'

const CreateUserTemplate = (locals) => (
  <Grid noPadding>
    <Grid.Row>
      <Grid.Col cols={16}>
        {locals.inputs.username}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={16}>
        {locals.inputs.password}
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col cols={16}>
        {locals.inputs.passwordConfirmation}
      </Grid.Col>
    </Grid.Row>
  </Grid>
)

export default CreateUserTemplate
